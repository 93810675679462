import styled from "styled-components";
import { STATUS_STYLES_MAPPING } from "./constants";
import { ENROLL_ACTION_COLOR } from "../constants";
import { blue } from "../../../../../styles/constants";

export const TitleWrapper = styled.span`
  text-transform: capitalize;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  width: fit-content;
`;

export const Details = styled.p`
  color: #7e7e8f;
  font-size: 16px;
  font-weight: 400;
`;

export const Email = styled.a`
  color: ${blue};

  &:focus-visible {
    outline: 0;
  }
`;

export const Status = styled.span`
  ${props => STATUS_STYLES_MAPPING[props.status]}
`;

export const getCustomPrimaryButtonStyles = () => {
  const bgColor = ENROLL_ACTION_COLOR;

  return `
    width: 163px;
    margin: 0;

    background-color: ${bgColor};

    &:hover {
      background-color: ${bgColor}CC;
    }
  `;
};

export const getCustomSecondaryButtonStyles = isNotEnrolled => `
  width: 163px;
  color: #9A9AAF;
  background-color: transparent;

  &:hover {
    background-color: #9A9AAF33 !important;
  }

  ${isNotEnrolled && "border: 1px solid #C6CBD9"}
`;
