import React from "react";
import { PropTypes } from "prop-types";
import DetailItem from "../../../../../components/DetailItem";
import { WorkerStyles as styles } from "../../../../../styles";
import { getLabelIcon } from "./utils";
import teamMember from "../../../../../models/team-member";

const EnrollmentDetails = ({ details, onValueAction }) => {
  const enrollment_details = details.filter(detail => !!detail.value);

  return (
    <div>
      {enrollment_details.map((detail, index) => {
        const handleActionClick = detail => {
          if (
            teamMember.canTriggerEnrollment &&
            (detail.label == "mvr"
              ? teamMember.canTriggerCmMvr
              : teamMember.canTriggerCmCrim)
          ) {
            onValueAction(detail);
          }
        };
        return (
          <div
            style={
              index + 1 === enrollment_details.length ? {} : styles.divider
            }
            key={index}
          >
            <DetailItem
              detail={detail}
              enableValueAction={
                teamMember.canTriggerEnrollment &&
                (detail.label == "mvr"
                  ? !teamMember.canTriggerCmMvr
                  : !teamMember.canTriggerCmCrim)
              }
              disableRow={detail.status === "not_enrolled"}
              onValueAction={() => handleActionClick(detail)}
              containerStyle={styles.detailContainer}
              labelIcon={
                <div style={{ marginRight: "25px" }}>
                  {getLabelIcon(detail)}
                </div>
              }
              labelStyle={{
                ...styles.detailLabel,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textTransform: "capitalize",
                ...detail.labelStyle
              }}
              descriptionStyle={{
                ...styles.detailDescription,
                ...detail.valueStyle,
                cursor: "pointer"
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

EnrollmentDetails.propTypes = {
  details: PropTypes.array,
  onValueAction: PropTypes.func
};
EnrollmentDetails.defaultProps = {
  details: []
};

export default EnrollmentDetails;
