export const ENROLLED_LIGHT_COLOR = "#E3F8E9";
export const ENROLLED_DARK_COLOR = "#1FC569";
export const ENROLL_ACTION_COLOR = "#7364DB";

export const UNENROLLED_LIGHT_COLOR = "#EC8C5633";
export const UNENROLLED_DARK_COLOR = "#EC8C56";

export const NOT_ENROLLED_COLOR = "#70707C";

export const ENROLLMENTS_DATA_MAPPING = {
  enrolled: {
    value: "Enrolled",
    valueStyle: { color: ENROLLED_DARK_COLOR }
  },
  not_enrolled: {
    value: "Not Enrolled",
    valueStyle: { color: NOT_ENROLLED_COLOR }
  },
  unenrolled: {
    value: "Unenrolled",
    valueStyle: { color: UNENROLLED_DARK_COLOR }
  }
};
