import { ENROLLED_DARK_COLOR, UNENROLLED_DARK_COLOR } from "../constants";

export const DEFAULT_ERROR_MESSAGE =
  "There was an error, please try again or contact support at support@turn.ai";

export const STATUS_STYLES_MAPPING = {
  enrolled: `
    color: ${ENROLLED_DARK_COLOR};
  `,
  unenrolled: `
    color: ${UNENROLLED_DARK_COLOR};
  `
};

export const PRIMARY_BUTTON_LABEL_MAPPING = {
  enrolled: "Unenroll",
  unenrolled: "Enroll"
};

export const STATUS_MAPPING = {
  enrolled: "enrolled",
  not_enrolled: "not_enrolled",
  unenrolled: "unenrolled"
};

export const OPPOSITE_STATUS_MAPPING = {
  enrolled: STATUS_MAPPING.unenrolled,
  unenrolled: STATUS_MAPPING.enrolled
};

export const TOASTER_OPTIONS = {
  closeButton: true,
  positionClass: "toast-bottom-right",
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "10000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  preventDuplicates: true,
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut"
};
